/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

.ContactForm {
    &-Header {
        h3 {
            font-size: 28px;
            text-transform: unset;
            color: var(--tilte--color);
            margin-block-start: 0px;
            padding-block-start: 0px;
            line-height: 20px;

            @include mobile {
                font-size: 24px;
            }
        }
    }

    &-Description {
        font-size: 16px;
        line-height: 24px;
        color: #666;

        @include mobile {
            font-size: 14px;
        }
    }

    &-Field {
        margin-block-start: 24px;

        input,
        textarea {
            width: 100%;
        }

        textarea {
            min-height: 120px;
        }

        label {
            font-size: 16px;
            font-weight: 500;
            font-family: "Roboto-Light", sans-serif;
            color: #0a0903;

            @include mobile {
                font-size: 14px;
            }
        }
    }

    button {
        margin-block-start: 24px;
        width: 100%;
    }

    form {
        margin: 0 auto;

        @include desktop {
            max-width: 650px;
        }
    }
}

.contactus-page-image img {
    @include desktop {
        margin-block-end: 30px;
    }
}

h3.ContactPage-ContactsHeader {
    margin-block-start: 0px;
}
