/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
/* stylelint-disable declaration-no-important */

:root {
    --footer-content-height: 220px;
    --footer-copyright-height: 50px;
    --footer-nav-height: 48px;
    --footer-total-height: calc(env(safe-area-inset-bottom) + var(--footer-nav-height));

    --footer-background-color: var(--primary-blue-color);
    --footer-item-color: rgb(255, 255, 255);
}

.Footer {
    $column-count: 4;

    font-family: 'Roboto-Regular', sans-serif;

    &-CopyrightContentWrapper {
        background-color: var(--footer-background-color);
        color: var(--footer-item-color);
        //padding: 10px 80px;


        @include mobile {
            text-align: center;
            padding-block-start: 20px;
            padding-block-end: 100px;
            display: none;
        }

        &::before {
            @mixin copyrightLine {
                display: block;
                width: 100%;
                content: '';
                margin-block-start: 30px;
                border-bottom: 1px solid rgb(221, 221, 221);
                max-width: var(--content-wrapper-width);
                margin: auto;
            }

            @include desktop {
                @include copyrightLine;
            }

            @include tablet {
                @include copyrightLine;
            }
        }
    }

    &-CopyrightContent {
        padding: 40px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: var(--footer-copyright-height);

        @include desktop {
            padding-block-end: 48px;
        }

        @include mobile {
            padding-block-start: 0;
            padding-block-end: 100px;
        }
    }

    &-Copyright {
        font-size: 12px;
        text-align: center;
        color: var(--footer-item-color);
        line-height: 16px;

        @include desktop {
            width: 100%;
            display: inline-block;
            padding: 10px 0;
        }


        a {
            color: inherit;
            font-weight: 400;
            font-size: 12px;

            &:hover {
                color: var(--primary-base-color);
            }
        }
    }

    &-Content {
        @include desktop {
            min-height: var(--footer-content-height);
        }

        @include ultra-wide-desktop {
            padding-inline: 20px;
        }

        background-color: var(--footer-background-color);
        color: var(--footer-item-color);

        @include mobile {
            .Footer-Columns {
                display: none;
                border-bottom: 1px solid #727DA4;
                margin: 0px 20px 0px;
                padding: 30px 0 0;
            }
        }

        @include desktop {
            .Footer-Columns {
                margin: auto;
                max-width: var(--content-wrapper-width);
            }
        }
    }

    &-Columns {
        display: flex;
        height: 100%;
        justify-content: space-between;

        svg path {
            fill: var(--footer-item-color);
        }

        @include mobile {
            flex-direction: column;
            align-items: center;
            text-align: center;
            padding: 50px 16px 0 16px;
        }

        @include desktop {
            padding-inline: 0px;
            padding-block-start: 40px;
        }

        @include tablet {
            padding-inline: 30px;
        }
    }

    &-ColumnForMobile {
        @include desktop {
            display: none;
        }

        @include tablet {
            display: none;
        }

        @include mobile {
            width: 100%;
            text-align: start;
        }

        &:first-child {
            @include mobile {
                margin-block-end: 30px !important;
            }
        }

        &:not(:last-of-type) {
            @include mobile {
                margin-block-end: 10px;
            }

            @include desktop {
                padding-inline-end: 20px;
                max-width: calc(100% / #{ $column-count - 1 });
            }
        }

        &_type_companyInfo {
            @include mobile {
                display: none;
            }
        }

        &_type_socialForMobile {
            @include desktop {
                display: none;
            }

            @include tablet {
                display: none;
            }
        }

        &_type_socialForNotMobile {
            @include mobile {
                display: none;
            }
        }

        .ExpandableContent {
            border-top: 1px solid #727DA4;
            border-bottom: none;

            &-Button {
                &_isContentExpanded {
                    padding-block-end: 30px;
                }

                svg {
                    stroke: var(--primary-blue-color);
                    width: 25px;
                    height: 25px;
                }
            }

            &-Content {
                &_isContentExpanded {
                    padding-block-end: 20px;
                }
            }


            &-Heading {
                margin: 0;
                color: var(--footer-item-color);
                font-size: 14px;
                font-family: 'Roboto-Regular', sans-serif;
                font-weight: 500;
                text-transform: uppercase;
            }
        }
    }

    &-Column {
        @include mobile {
            display: none;
        }

        &:not(:last-of-type) {
            @include mobile {
                margin-block-end: 30px;
            }

            @include desktop {
                padding-inline-end: 20px;
                max-width: calc(100% / #{ $column-count - 1 });
            }
        }

        &:not(:first-of-type) {
            .Footer-ColumnItem {
                margin-block-end: 20px;
            }
        }

        &_type_companyInfo {
            @include mobile {
                display: none;
            }
        }

        &_type_socialForMobile {
            @include desktop {
                display: none;
            }

            @include tablet {
                display: none;
            }
        }

        &_type_socialForNotMobile {
            @include mobile {
                display: none;
            }

            .Footer-ColumnTitle {
                font-size: 20px;
            }
        }
    }

    &-ColumnTitle {
        margin: 0;
        margin-block-end: 15px;
        color: var(--footer-item-color);
        text-transform: uppercase;
        font-weight: 500;
        font-size: 20px;

        @include desktop {
            margin-block-end: 20px;
        }
    }

    &-ColumnContent {
        display: flex;
        flex-direction: column;
        color: var(--footer-item-color);

        &_direction_horizontal {
            flex-direction: row;

            @include mobile {
                justify-content: center;
            }
        }
    }

    &-ColumnItem {
        border-radius: 50%;
        color: #99B8DC;
        margin-block-end: 12px;
        font-size: 16px;

        @include double-ultra-wide-desktop {
            font-size: 13px;
        }

        font-weight: 400;
        line-height: 14px;


        @include mobile {
            font-size: 12px;
            margin-block-end: 30px;
        }

        &:last-of-type {
            margin: 0;
        }

        &:hover {
            text-decoration: underline;
            color: var(--footer-item-color);
        }

        &Image {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                // important required as default 100% width is passed as inline style
                // + you can't achieve same centering with just passing '16px' as inline style for the case
                /*  width: 16px !important;
                  inset-block-start: 50%;
                  inset-inline-start: 50%;
                  transform: translate(-50%, -50%);*/

                &:dir(rtl) {
                    /* transform: translate(50%, -50%);*/
                }

                &:hover {
                    opacity: 0.6;
                }
            }
        }

        &_type_image {
            width: 29px;
            height: 32px;
            // border: 1px solid var(--secondary-dark-color);

            &:not(:last-of-type) {
                margin-inline-end: 20px;
                margin-block-end: 0;
            }
        }

        &_notLink:hover {
            text-decoration-line: none;
        }

        &_display_forMobile {
            @include desktop {
                display: none;
            }

            @include tablet {
                display: none;
            }
        }

        &_display_forNotMobile {
            @include mobile {
                display: none;
            }
        }

        & a {
            color: var(--footer-item-color);
            font-weight: normal;
        }
    }

    &-CmsBlockWrapper {
        .CmsBlock-Wrapper {
            flex: 1;
        }

        .ContentWrapper .Footer-Columns {
            padding: 0;
        }

        .Footer-Column_isNewsletter {
            @include desktop {
                padding-inline-start: 100px;
            }
        }
    }

    .ExpandableContent-Button {
        @include mobile {
            padding-block-start: 0;
            padding-block-end: 0;
        }
    }

    .Footer-ColumnForMobile {
        .ExpandableContent-Content_isContentExpanded {
            @include mobile {
                padding-block-end: 0;
            }
        }
    }

    .Footer-ColumnItem {
        @include mobile {
            margin-block-start: 5px;
            margin-block-end: 5px;
        }
    }
}

.Footer-ColumnItem.Footer-ColumnItem_notLink {
    line-height: 6px;
}

.Footer-ColumnItem.Footer-ColumnItem_notLink:hover {
    color: #99B8DC;
}

.Footer-ColumnItem.Footer-ColumnItem_notLink:last-child {
    margin-block-end: 40px;
}

.footer-address {
    margin-block-start: 40px;
}

.address-phone,.address-email {
    font-size: 20px;
    font-family: "Roboto-Bold", sans-serif;

    a {
        font-size: 16px;
        line-height: 12px;
    }
}

// For Footer Top Padding

.HomePage {
    margin-block-end: 2px;

    @include mobile {
        margin-block-end: 100px;
    }
}

.CategoryPage {
    margin-block-end: 30px;
}

.ContactPage {
    //margin-block-end: 30px;

    @include mobile {
        margin-block-end: 64px;
    }
}
//  End --For Footer Top Padding

.pci-footer {
    width: 34%;
    display: flex;
    align-items: center;

    @include narrow-tablet {
        width: 25%;
    }

    .app-text {
        justify-content: left;
        display: inline-block;

        h5 {
            margin-block-end: 12px;
        }

        @include narrow-tablet {
            width: 60%;
        }
    }

    .app-logo {
        justify-content: left;

        @include narrow-tablet {
            width: 30%;
        }
    }
}

.app-footer {
    width: 38%;
    display: flex;

    @include narrow-tablet {
        width: 45%;
    }

    .app-logo {
        @include narrow-tablet {
            width: 30%;
        }
    }

    .app-text {
        @include narrow-tablet {
            width: 40%;
        }
    }
}

.address {
    width: 26%;

    @include narrow-tablet {
        width: 36%;
    }

    @include double-ultra-wide-desktop {
        width: 30%;
    }

    h3 {
        line-height: 0px;
        padding-block-end: 20px;
    }

    .Footer-ColumnItem {
        margin-block-end: 20px;
    }
}

.app-text {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: right;

    @include narrow-tablet {
        width: 30%;
    }

    @include double-ultra-wide-desktop {
        width: 30%;
    }

    h3 {
        @include double-ultra-wide-desktop {
            font-size: 13px;
        }

        color: #ffffff;
    }
}

.app-logo {
    width: 35%;

    @include narrow-tablet {
        width: 10%;
    }

    @include double-ultra-wide-desktop {
        width: 23%;

        svg {
            width: 100%;
        }
    }

    display: flex;
    align-items: center;
    justify-content: right;
}

.CATEGORIES-BANNER-WRAPPER {
    @include double-ultra-wide-desktop {
        padding-inline: 20px;
    }
}

.homepage-product-slider {
    @include double-ultra-wide-desktop {
        padding-inline: 20px;
    }
}

.corp-section {
    @include desktop {
        width: 50%;
        margin: auto;
        display: flex;
    }
}

.cor-form-form {
    margin-block-end: 30px;
    border-radius: 12px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    padding: 30px 30px;

    @include  mobile {
        margin-block-start: 16px;
    }


    .Field:first-child {
        margin-block-start: 0px;
    }

    .CorporateAccountForm-Field:first-child {
        margin-block-start: 0px;
    }
}

.cor-form-icon,.cor-form-form {
    display: inline-block;
    width: 50%;

    @include mobile {
        width: 90%;
        margin-inline: 5%;
        //display: flex;
    }
}

.cor-form-icon {
    @include mobile {
        padding-block-start: 10px;
    }
}

h2.PAGE-TITLE,h1.PAGE-TITLE,.FAQ-PAGE h1 {
    padding-block-start: 10px;
}

.ReactModal__Overlay--after-open {
    background-color: rgba(000, 000, 000, 0.75) !important;
}

.popupCloseButton {
    inset-inline-end: 10%;

    @include  mobile {
        inset-inline-end: 3%;
    }

    border-radius: 50%;
    inset-block-start: 0%;
    cursor: pointer;
}

/* spin start */

.shopping {
    width: 239px;
    height: 54px;
    display: flex;
    /* text-align: center; */
    align-items: center;
    border-radius: 12px;
    background: #EB3434;
    margin: auto;
}

a.button-success {
    justify-content: center;
    margin: auto;
    color: #FFF;
    font-weight: 700;
    font-size: 16px;
    text-decoration: unset;
    font-family: 'Roboto-Bold', sans-serif;
}

.congrats {
    color: #214991;
    text-align: center;
    font-size: 44px;
    font-weight: 600;
    line-height: normal;
    margin: auto;

    @include mobile {
        font-size: 24px;
    }
}

.success-text {
    color: #5C647B;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-block-end: 10px;
    margin-block-start: 24px;

    @include mobile {
        margin-block-end: 16px;
    }
}

.product-name {
    color: #22336A;
    text-align: center;
    font-size: 24px;
    margin-block-start: 12px;
    margin-block-end: 19px;

    @include mobile {
        font-size: 20px;
    }
}

.product-name > span {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;

    @include mobile {
        font-size: 20px;
        display: flex;
        justify-content: center;
    }
}

.win-popup {
    border-radius: 24px;
    background: #FFF;
    width: 600px;
    height: auto;
    box-shadow: 0px 2px 34px 0px rgba(0, 0, 0, 0.25);
    padding: 50px 0px;
    margin: auto;

    @include mobile {
        width: 95%;
        margin: auto;
        padding: 24px 24px;
    }

    img {
        width: auto;
        height: 230px;

        @include mobile {
            height: 150px;
        }

        display: flex;
        margin: auto;
    }
}

/* spin End */

.secure-card-icons {
    display: flex;

    p {
        margin-block-end: 0px;
        color: #767983;
        font-size: 14px;
        font-weight: 500;

        @include  mobile {
            margin-block-end: 5px;
        }
    }

    @include  mobile {
        display: grid;
        grid-template-columns: auto auto;
    }

    padding: 0px 0px;
}
// card checkout,bitcash and saved card

.card-input-form {
    padding: 0px 30px;

    @include mobile {
        padding: 0px 10px;
    }
}

.Field-Wrapper_name_save_card_details {
    margin: 16px 0px;
}

.CheckoutPayment-Button {
    @include mobile {
        padding: 30px 10px !important;
    }
}

.pci-save-card {
    @include mobile {
        padding-block-start: 17px;
    }
}
