/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../../style/abstract/variables';
@import '../../../style/abstract/media';
@import '../../../style/abstract/button';
@import '../../../style/abstract/loader';
@import '../../../style/abstract/icons';
@import '../../../style/abstract/parts';

/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

.SaveCardIcon {
    path {
        fill: var(--primary-blue-color);
    }
}
