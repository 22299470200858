/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

/* stylelint-disable declaration-no-important */

:root {
    --content-wrapper-width: 1440px;
}

.ContentWrapper {
    max-width: var(--content-wrapper-width);
    margin-inline: auto;

    @include mobile {
        padding-inline: 14px;
        //padding-block-end: 20px;
    }

    @include double-ultra-wide-desktop {
        padding: 0 20px 0 !important;
    }
}

section {
    .ContentWrapper {
        @include mobile {
            -webkit-padding-before: calc(20px + env(safe-area-inset-top))!important;
            padding-block-start: calc(20px + env(safe-area-inset-top)) !important;
        }
    }
}

.HomePage {
    section {
        .ContentWrapper {
            @include mobile {
                -webkit-padding-before: 10px!important;
                padding-block-start: 10px!important;
            }
        }
    }
}

.CategoryPage {
    section {
        .ContentWrapper {
            @include mobile {
                padding: 0!important;
                //-webkit-padding-before: calc(0px + env(safe-area-inset-top))!important;
                //padding-block-start: calc(0px + env(safe-area-inset-top))!important;
                margin-block-start: 0px;
            }

            .CategoryDetails-Description {
                @include mobile {
                    padding: 0;
                }
            }
        }
    }
}

.homepage-product-slider {
    .ContentWrapper {
        @include mobile {
            padding-inline-start: 8px;
            padding-inline-end: 14px;
        }
    }
}
