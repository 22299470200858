.HERO-BANNER-SLIDER-WRAPPER {
    background-color: #FFFFFF;

    @include ultra-wide-desktop {
        padding: 0;
    }

    .SliderWidget {
        &-Figure {
            img {
                height: auto;
            }

            .Image-Image {
                position: relative;
            }
        }
    }

    .SliderWidget-FigureImage {
        padding-block-end: 0;
        display: flex;
    }
}
