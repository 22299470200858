/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    // Colors
    --color-black: #{$black};
    --color-white: #{$white};
    --color-gray: #f3f3f2;
    --color-dark-gray: #dddddd;
    --color-karaka: #0a0903;
    --primary-divider-color: #e0e0e0;
    --secondary-error-color: #fff;
    --primary-error-color: #d20b0b;
    --secondary-success-color: #fff;
    --primary-success-color: #2fbf71;
    --secondary-info-color: var(--color-karaka);
    --primary-info-color: #ffec51;
    --body-content-color: var(--color-black);
    // Masafi colors
    --primary-blue-color: #004EA8;
    --tilte--color: #22336A;
    --secondary-blue-color: #0296E5;
    --primary-yellow-color: #fef400;
    --primary-grey-color: #E2E4E7;
    --primary-red-color: #E60028;
    // ToDo Check if it's safe to replace --primary-error-color with those
    --warning-color: #8E5555;
    --warning-background: #fef5f5;
    --success-color: #5C7E5A;
    --success-background: #edf7ed;

    $default-primary-base-color:  var(--secondary-blue-color);
    $default-primary-dark-color: var(--primary-blue-color);

    // $default-secondary-base-color: #0296E5;

    // Placeholders
    --placeholder-gradient-color: #d9d9d9;
    --placeholder-image: linear-gradient(to right, #ebebeb 0, var(--placeholder-gradient-color) 15%, #ebebeb 30%);
    --placeholder-size: 1200px 100%;
    --placeholder-animation: placeholderAnimation 2s linear infinite;

    // Declare imported primary colors to change the theme's colors
    // "imported_..." variables are set up in Admin panel -> Stores -> Configuration -> Scandipwa -> Color Configuration
    // They have higher priority than "default_..." variables defined in code.
    --primary-base-color: var(--imported_primary_base_color, #{$default-primary-base-color});
    --primary-dark-color: var(--imported_primary_dark_color, #{$default-primary-dark-color});
    --primary-light-color: var(--imported_primary_light_color, #{$default-primary-light-color});
    --secondary-base-color: var(--imported_secondary_base_color, #{$default-secondary-base-color});
    --secondary-dark-color: var(--imported_secondary_dark_color, #{$default-secondary-dark-color});
    --secondary-light-color: var(--imported_secondary_light_color, #{$default-secondary-light-color});
}
