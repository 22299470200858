/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

.SocialSignIn {
    h2.SocialSignIn-Heading {
        @include mobile {
            margin: 0px 0px;
        }
    }

    &-Heading {
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 28px;
    }

    &-Btns {
        @include mobile {
            display: flex;
            gap: 30px;
            justify-content: center;

            &-BtnWrapper {
                width: 100%;

                .apple-auth-btn {
                    width: 100%;
                    margin-block-end: 14px;
                    padding: 0;
                    font-size: 16px;
                    font-weight: bold;
                    border-radius: 10px;
                }
            }
        }

        &-Btn {
            width: 50px;
            height: 50px;
            background-color: #FFFFFF;
            box-shadow: 0 2px 6px 0 #00000026;
            align-items: center;
            justify-content: start;
            margin-block-end: 24px;

            @include desktop {
                padding: 0 30px;
                display: inline-flex;
                width: 260px;
            }

            &:hover {
                cursor: pointer;
            }

            svg {
                #Facebook {
                    path {
                        width: 10px;
                        height: 20px;
                        fill: #0658C4
                    }
                }
            }


            &-Label {
                padding-inline-start: 10px;
                color: #6C6F7A;
                font-size: 16px;
                line-height: 22px;

                @include mobile {
                    display: none;
                }
            }
        }
    }

    &_isPopup {
        text-align: center;

        .SocialSignIn-Btns {
            display: flex;
            gap: 30px;
            justify-content: center;

            &-Btn {
                display: inline-block;
                width: 50px;
                height: 50px;
                padding: 0;

                &-Label {
                    display: none;
                }
            }
        }
    }
}

.SocialSignIn-Btns-BtnWrapper {
    width: 100%;
}

.SocialSignIn-Btns-BtnWrapper .react-apple-signin-auth-btn {
    width: 100%;
    border-color: #000;
    font-weight: bold;
    font-size: 16px;
    border-radius: 10px;
}
