@font-face {
    font-family: 'Roboto-Regular';
    src: url(../fonts/SFTSchriftedSans-Regular.woff2) format('woff2'), url(../fonts/SFTSchriftedSans-Regular.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Light';
    src: url(../fonts/SFTSchriftedSans-Medium.woff2) format('woff2'), url(../fonts/SFTSchriftedSans-Medium.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Bold';
    src: url(../fonts/SFTSchriftedSans-Bold.woff2) format('woff2'), url(../fonts/SFTSchriftedSans-Bold.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}
