/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

.ContactPage {
    &-Heading {
        font-size: 36px;
        color: var(--primary-blue-color);

        @include mobile {
            font-size: 24px;
        }
    }

    &-ColumnWrapper {
        display: block;

        @include desktop {
            margin-block-end: 0px;
            margin-block-start: 0px;
        }
    }

    &-Column {
        margin-block-end: auto;
    }

    &-ContactsHeader {
        font-size: 28px;
        text-transform: unset;

        @include mobile {
            font-size: 24px;
        }
    }

    &-Address {
        color: #6c6f7a;
    }

    &-AddressDetails {
        font-size: 16px;
        font-style: normal;
        line-height: 22px;
        color: #666;
    }

    &-ContactSources {
        margin-block-start: 30px;
    }

    &-ContactSource {
        margin-block-start: 8px;
        display: flex;
        align-items: center;
        color: #6c6f7a;

        &,
        a {
            font-size: 16px;
            font-weight: 400;
            color: #22336B;

            @include mobile {
                font-size: 14px;
            }
        }

        svg {
            margin-inline-end: 10px;
        }
    }

    &-divider {
        border-top: 1px solid #e2e4e7;
        margin: 40px 0;
    }
}

.Breadcrumbs.contact {
    display: none;
}

.contactus-page-image {
    max-width: var(--content-wrapper-width);
    margin: auto;

    @include desktop {
        .Breadcrumbs.contact {
            display: block;
        }
    }
}

[dir="ltr"] .ContactPage-Column_isContent {
    margin: auto;
}

