/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

/* stylelint-disable declaration-no-important */

.Breadcrumbs {
    .ContentWrapper {
        @include ultra-wide-desktop {
            padding: 0 20px 0 !important;
        }

        border-bottom: 1px solid #C8CCDA;
    }

    .Breadcrumbs-List {
        padding: 15px 0 10px 0;

        li {
            margin-block-end: 0;

            &:last-child {
                .Breadcrumb-Link {
                    color: var(--primary-blue-color);
                }
            }
        }
    }
}
