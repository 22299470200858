/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

.CorporateAccountForm {
    &-Header {
        h2 {
            font-size: 28px;
            text-transform: unset;
            color: var(--primary-blue-color);
            text-align: center;
            margin-block-end: 50px;

            @include mobile {
                font-size: 24px;
            }
        }

        p {
            text-align: center;
        }
    }

    &-Description {
        font-size: 16px;
        line-height: 24px;
        color: #6c6f7a;

        @include mobile {
            font-size: 14px;
        }
    }

    &-Field {
        margin-block-start: 24px;

        input,
        textarea {
            width: 100%;
        }

        textarea {
            min-height: 120px;
        }

        label {
            font-size: 16px;
            font-weight: 500;

            @include mobile {
                font-size: 14px;
            }
        }
    }

    button {
        margin-block-start: 24px;
        width: 100%;
    }

    form {
        margin: 0 auto;

        @include desktop {
            margin-block-end: 50px;
        }

        @include mobile {
            margin-block-start: 50px;
            margin-block-end: 50px;
        }
    }

    form img {
        width: 100px;
        float: inline-start;
    }

    form ul {
        display: inline-flex;
    }

    form ul li div {
        float: inline-start;
        width: 250px;
        padding-inline-start: 12px;
    }

    li {
        &::before {
            display: none;
        }
    }

    .CorporateFormTopHeader {
        margin: 0 auto;

        img {
            width: 80px;
            height: 80px;
        }

        .img-corporate {
            width: 100%;
            height: 100%;
            margin-block-end: 30px;
        }

        ul {
            display: inline-flex;
        }

        ul li {
            display: flex;
            width: 100%;
            padding-inline-end: 10px;
        }

        ul li h3 {
            color: #23336b;
            margin: 0px;
        }

        ul li p {
            color: #aaa;
        }

        @include mobile {
            ul {
                display: block;
                margin-block-end: 20px;
            }

            li {
                margin-block-end: 20px;
                border-bottom: 1px solid #eee;
            }

            img {
                width: 60px;
                height: 50px;
            }
        }
    }
}

.resspo {
    width: 100%;
    display: flex;
}
